import React from "react";
import { Container, Breadcrumb} from "react-bootstrap";
import {Link} from 'gatsby'
import PageWrapper from "../../components/PageWrapper";
import { Section, Title, Post } from "../../components/Core";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import {Helmet} from "react-helmet";
import {StaticImage} from 'gatsby-plugin-image';

function startabusiness() {
    return (
        <>
        <PageWrapper footerDark>
    	<Helmet>
                <meta charSet="utf-8" />
                <title>Erreurs à eviter de la banque sur internet - liste complète</title>
                <meta name="description" content="
              Liste complète des erreurs à eviter avec la banque sur internet. Evitez les surprises en protégeant vos données et vos transactions bancaires." />
        </Helmet>
      	<Header />
      	<Section>
      	<Container style={{maxWidth: 1280}}>
		  <Breadcrumb id='breadyTop'>
			<Breadcrumb.Item> <Link to={`/`} style={{color: 'black'}}>Accueil</Link></Breadcrumb.Item>
			<Breadcrumb.Item> <Link to={`/academie/`} style={{color: 'black'}}>Banque Académie</Link></Breadcrumb.Item>
			<Breadcrumb.Item active id='bready'>Erreurs à éviter avec une banque en ligne</Breadcrumb.Item>
      	 </Breadcrumb>
        	<Title id='mobileTitle'>Erreurs à éviter avec une banque en ligne</Title>
			<StaticImage
		  	src='../../images/erreurs-a-eviter-banque-en-ligne.jpeg'
			width={1200}
			alt='Erreurs à éviter'
			placeholder='tracedSVG'
			id='pickky'
			/>
			<Post>
		<p>	Tarifs attractifs, offre de bienvenue, conseillers disponibles, simplicité, …. Faut-il se laisser séduire par les banques en ligne ? Elles présentent indiscutablement plusieurs avantages, cependant, quelques précautions de sécurité sont à prendre en compte avant d’effectuer toutes opérations bancaires en ligne.  En effet, même si les banques en ligne s’engagent à entreprendre des relations sécurisées avec leurs clients, le risque zéro n’existe pas. Pour ceux qui s’interrogent sur la sécurité des banques en ligne, BankBank a listé les pratiques de sécurité nécessaires pour maintenir un environnement sécurisé.
		</p>
<h2>Avoir un comportement prudent</h2>
<h3>Renouveler régulièrement son code secret</h3>
<p>Pour des raisons de sécurité, il convient de personnaliser son code secret, mais pensez également à le changer régulièrement.
</p>
<h3>Système d’exploitation et de navigation à jour</h3>
<p>Il est nécessaire de mettre à jour régulièrement son système d’exploitation et de navigation pour éviter toute faille de sécurité, et éviter les piratages informatiques.
</p>
<h3>Anti-virus et un anti-spyware performant</h3>
<p>Ces logiciels vont permettre de détecter les logiciels malveillants,  présent sur l’ordinateur. Une mise à jour et un scan régulier sont nécessaires.
</p>
<h3>Un pare-feu correctement paramétré</h3>
<p>Vérifier l’adresse indiquée sur son navigateur Internet</p><p>
Avant de se connecter à son espace personnel, il est indispensable de vérifier que l’adresse affichée est strictement identique à celle de la banque.</p>

<h3>Se déconnecter après chaque utilisation en ligne</h3>
<p>La déconnexion de son espace personnel doit systématiquement être effectuée en cliquant sur le bouton « déconnexion ».
</p>
<h3>Des réflexes supplémentaires pour les paiements sur internet</h3>
<p>Plusieurs précautions sont à prendre avant de réaliser un paiement sur internet: vérifiez que le site est sécurisé, n’enregistrez jamais vos coordonnées bancaires, ne donnez son code personnel, vérifiez l’identité du marchand, évitez de se connecter depuis un réseau WIFI public.
</p>
<h2>Techniques de piratages les plus utilisés</h2>
<p>Comprendre les techniques de piratage  permet de mieux le repérer, et donc de mieux se protéger.
</p>
<h3>Se prémunir contre le phishing</h3>
<p>Le Phishing est une technique frauduleuse consistant à se faire passer par des organismes officiels, et obtenir des internautes leur mots de passe, ou encore leur numéro de cartes bancaires. Pour cela, il est conseillé de ne jamais cliquer sur un lien contenu dans un email ou encore de signaler rapidement tout email suspect.
</p>
<h3>Lutter contre le Spyware</h3>
<p>Pouvant représenter une menace sûre, cette technique permet d’enregistrer l’activité des internautes, et les renvoient à des tiers. Il est conseillé de ne pas visiter ou  télécharger sur des sites dangereux, ou encore de ne pas ouvrir des emails indésirables
</p>
<h3>Se protéger contre le farming</h3>
<p>Ce type de virus consiste principalement à rediriger  l’internaute vers un faux site. Une mise à jour et un comportement responsable sont conseillés.
</p><p>
N’hésitez pas à utiliser le comparateur BankBank pour trouver la banque en ligne la plus adaptée à votre profil. En quelques clics, obtenez un comparatif des meilleures offres disponibles sur le marché.</p>
			</Post>
		</Container>
		</Section>
		<Footer/>
      </PageWrapper>
    </>
    )
}

export default startabusiness
